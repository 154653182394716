<div class="zoomed-content">
    <div class="standalone">
        <div class="standalone__header-bar">
            <div class="standalone__header">
                <img class="standalone__logo" src="../assets/images/Hub_White.png" />
                <h1 class="standalone__title">Lead Profile</h1>
            </div>
        </div>
        <div class="lead-profile">
            <form class="pin-entry pin-entry" *ngIf="!authorized"  (ngSubmit)="authorizeLead()">
                <div class="pin-entry__prompt-text">Please enter your 6-digit PIN:</div>
                <input [(ngModel)]="pin" name="pin" pattern="[0-9]*" type="password" maxlength="6" class="input-control" autofocus />
                <button type="submit" class="button button--primary">Get Profile</button> 
                <div class="pin-entry__error-text" *ngIf="errorMessage !== ''">{{ errorMessage }}</div>
            </form>
        </div>

        <div class="standalone__content" *ngIf="authorized">
            <app-prospect-questionnaire [leadData]="leadData"></app-prospect-questionnaire>
        </div>
    </div>
</div>