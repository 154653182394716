import * as internal from "stream";


  export class ProspectList{
    prospectid:number;
    name:string;    
    url:string;
    flag:number;
  }
  
  export class Time_Zone_List{
    name:string;    
    code:string;
  }

  export class Practice_Area_List{
    name:string;    
    code:string;
  }

  export class Week_List{
    name:string;    
    code:string;
  }

  export class ChartandTableDetails{ 
      date:Date;
      total:number;
      disputed:number;
      nonbillable:number;
      nonbillableims:number;
      duplicate:number;
      billable:number;    
      prospectcount: number;
    }

    export class ProspectDetails{
    prospectid:number;
    prospectname:string;
    timezone:string;
    practicearea:string;
    week:string;
    billnonbill:string;
    tableDetails:ChartandTableDetails[];
    responderid:string;
    responderNoteId:number;
    inqrDetails:LeadDetails[];
    eventValidity:number;
    hubSource:string=null;
    totalProspectCount:number=null;
    }


    export class ProspectChartandTableDetails{
      date:string;
      prospectcount:Number;
      Name:string      
      ReferalDate:Date;
      CityState:string;
      Phone:string;
      Email:string;
      PracticeArea:string;
      Status:string;
    }

    export class Prospects{
    prospectid:number;
    prospectname:string;
    timezone:string;
    practicearea:string;
    week:string;
    billnonbill:string;
    tableDetails:ProspectChartandTableDetails[];
    }

    export interface ProspectRoute
    {
      name:string,
      code:string
    }
    export interface TimeZone
    {
      name:string,
      code:string
    }
    
    export interface PracticeArea
    {
      name:string,
      code:string
    }
    export interface LeadsPerPg
    {
      name:string,
      code:string
    }
   
    export interface EventID
    {
     name:string,
     code:string
    }
    export class LeadDetails{
      pAid: number;
      date: string=null;
      clientId: number;
      lastName: string;
      phone: string;
      email: string;
      status: string;
   
    }
   
    export class PracticeAreaColor{
      id:number;
      name:string;
      colorCode:string;
      description:string;      
      practiceareacheck:boolean;     
      }
      export class LeadOrderTableDetails{
        leadRouteId:string;
        firstName:string;
        lastName:string;
        referralDate:Date;
        zipCode:string;  
        email:string;  
        phoneNumber:string;  
        practiceArea:string;  
        status:string;  
        description:string;  
        state:string;  
        city:string; 
        practiceareaid:number; 
        colorCode:string;   
        age: number = null;
        income: string = null;
        referralRoute:string;
        question:string;
        prospectResponse:string;        
        leadChecked: boolean; 
        practiceareacount:number;
        alternateNumber:string;
        addressLine1:string;
        addressLine2:string;
        clientId:string;
        leadNoteDetails:Lead_Notes_Details[]= [];  
        }
        export class LeadsQuestionnarieData
        {
          customerName :string;
          referralRoute:string;
          referralDate :string;
          name :string;
          phoneNumber :string;
          city :string;
          state:string;
          zip :string;
          email:string;
          question:string;
          prospectResponse:string;
          questionnaires:QuestionAnswer[];   
        }
        export class QuestionAnswer
        {
             question:string;
             prospectResponse:string;
        }
        export class LeadQuestionnaire
        {                            
            customerName :string;
            referralRoute:string;
            referralDate :Date;
            name :string;
            phoneNumber :string;
            city :string;
            state:string;
            zip :string;
            email:string;
            questionnaires:QuestionAnswer[];            
        }

        export class Lead_Notes_Details {
          leadId:number = 0;
          leadNoteId: number = null;
          note: string = null;
          dateCreated: string = null;
          dateUpdated: string = null;
          isDeleted:boolean=null;
      }
   