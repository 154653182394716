import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OneloginAuthGuard } from './shared/guard/onelogin-auth.guard';
import { AttendanceComponent } from './layout/standalone/attendance/attendance.component';
import { LeadProfileComponent } from './layout/standalone/lead-profile/lead-profile.component';

const routes: Routes = [
    { path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule), canActivate: [OneloginAuthGuard] },
    { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
    { path: 'signup', loadChildren: () => import('./signup/Signup.module').then(m => m.SignupModule) },
    { path: 'error', loadChildren: () => import('./server-error/server-error.module').then(m => m.ServerErrorModule) },
    { path: 'access-denied', loadChildren: () => import('./access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule) },
    { path: 'auth-callback',loadChildren: () => import('./auth-callback/auth-callback.module').then(m => m.AuthCallbackModule) },

    //route not behind onelogin
    { path: 'attendance', component: AttendanceComponent, data: { name: 'Attendance' } },
    { path: 'lead-profile', component: LeadProfileComponent, data: { name: 'Lead Profile' } },

    { path: '**', redirectTo: 'not-found' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],//, {useHash: true}
    exports: [RouterModule]
})
export class AppRoutingModule {}
