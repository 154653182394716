export const AppConstants ={

  /***Timeout Constants ***/
  TIMEOUT_TWO_THOUSAND_MS: 2000,
  TIMEOUT_FIVE_HUNDRED_MS: 500,

  /*** Constants For Home Screen ***/
  SCREEN_SHRINK_MIN_WIDTH: 1280,
  ENTER_KEY_ASCII: 13,

   /*** Constants For Card Module ***/
   WINDOW_MIN_WIDTH: 900,

   /* Session Expiration Time Interval (in seconds) */
   HUB_SESSION_TIMEOUT: 600,

   /*** Extend max guest allowed for an event ***/
   EXTEND_MAX_GUEST: 1,

   MESSAGE_TYPE_ERROR: "error",
   MESSAGE_TYPE_WARNING: "warn",
   MESSAGE_SUMMARY: "Error !",
   MESSAGE_WARNING: "Warning",
   MESSAGE_TYPE_SUCCESS: "success",
   MESSAGE_SUMMARY_SUCCESS: "Success",
   MESSAGE_DESC_SUCCESS: "Columns Saved Successfully.",
   MESSAGE_DESC: "Something Doesn't Seems To Be Right At The Moment. Please Try Again Later",

   /* Manage Prospect common component */
   MESSAGE_NO_GUESTS: "No Guests for this Event",
   LEAD_PROSPECT_TYPE: "Lead",
   EVENT_PROSPECT_TYPE: "Event",
   MESSAGE_EVENT_UPDATE_SUCCESS: "Prospect Details updated Successfully.",
   MESSAGE_LEAD_UPDATE_SUCCESS: "Lead Details updated Successfully.",
   MESSAGE_PROSPECT_UPDATE_ERROR: "Error occurred while updating",
   
   /*Disclaimer dialog*/
   REPORT_DEMOGRAPHICS: "DEMOGRAPHICS",
   REPORT_NAMES_GUESTS: "NAMES_AND_GUESTS",
   REPORT_EVENT_DETAIL: "EVENT_DETAILS",
   REPORT_EVENT_NUMBERS: "EVENT_NUMBERS",
   REPORT_SALE_INVOICE: "SALE_INVOICE_DATA",
   REPORT_PROSPECTS: "PROSPECTS",
   
   

   PROSPECT_DATE_FORMAT: "YYYY-MM-DD",
   PROSPECT_DAYS_ADD: 5,
   /*** Access Control Form***/

   SSO_ERROR_MSG: "SSO should be 9 digit number",
   VALUE_BLANK: "",

  /*** Constants for smart table module ***/
  CASE_FCE_QUEUE: "fceQueue",
  CASE_HM_QUEUE: "hmQueue",
  CASE_MY_TEAM_QUEUE: "myTeamQueue",
  CASE_TRANSFER_POPUP: "transferDetailsPopUp",
  COMPONENT_TYPE_CUSTOM: "custom",
  TABLE_NAME_FROM_BUC: "From BUC",
  TABLE_NAME_TO_BUC: "To BUC",
  DEFAULT_ROW_COUNT: 25,

  //MISC
  CUSTOM_DATE_RANGE: "Custom Date Range",
  //Link To GMS Portal
  GMS_LINK_URL: "https://www.gemoves.com/v2/gemovesV2/site/static/customer_service_default.php",

  MESSAGE_DATA_SUCCESS: "Data saved successfully.",
  DATE_RANGE_THRESHOLD:60,
  START_DATE_DEFAULT:30,
  END_DATE_DEFAULT:30,

  //Archive/Un-Archive
  ARCHIVE_LEVEL_EVENT:"EVENT_ARCHIVE",
  ARCHIVE_LEVEL_CAMPAIGN:"CAMPAIGN_ARCHIVE",
  ARCHIVE_EVENT_HEADER:"Confirm Archive",
  UNARCHIVE_EVENT_HEADER:"Confirm Un-Archive",
  ARCHIVE_HEADER:"Confirm Archive",
  UNARCHIVE_HEADER:"Confirm Un-Archive",
  ARCHIVE_EVENT_CONTENT:"This Event and its associated data will be archived. Are you sure you want to continue?",
  UNARCHIVE_EVENT_CONTENT:"This Event and its associated data will be un-archived. Are you sure you want to continue?",
  ARCHIVE_CONTENT:"This Campaign and its associated data will be archived.  Are you sure you want to continue?",
  UNARCHIVE_CONTENT:"This Campaign and its associated data will be un-archived.  Are you sure you want to continue?"

}

export const UrlConstants = {

  /** Dummy Service Call Urls **/

    DUMMY_SERVICE_URL: "dummyService",

    /***Service Call URLS ***/
    /*** Service variable for employee & requisition search ***/
    SER_REQUISITION_SEARCH_URL: "requisitionSearch/",
    SER_SSO_SEARCH_IN_QUEUE_URL: "empSearchInQueueHM/",
    SER_PIC_EXT: ".jpg"
}

export const Menus = {

  MenuItems : [
    //{
    //MainMenus:[
      //{label: "Dashboard", imageSrc:'./assets/images/Dashboard.svg', routerLink: '/dashboard',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Campaigns", imageSrc:'./assets/images/Campaigns.svg', routerLink: '/campaigns',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Events & Lead Routes", imageSrc:'./assets/images/Events.svg', routerLink: '/activities',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Prospects", imageSrc:'./assets/images/Prospects.svg', routerLink: '/prospects',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Performance Analysis", imageSrc:'./assets/images/PerformanceAnalysis.svg', routerLink: '/performance-analysis',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      // {label: "Analytics", imageSrc:'./assets/images/PerformanceAnalysis.svg', routerLink: '/analytics',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Sales Orders", imageSrc:'./assets/images/SalesOrders.svg', routerLink: '/sales-order',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Account Settings", imageSrc:'./assets/images/AccountSettings.svg', routerLink: '/account-setting',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Response Settings", imageSrc:'./assets/images/ResponseSettings.svg', routerLink: '/response-settings',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Exports & Integrations", imageSrc:'./assets/images/Integration&Export.svg', routerLink: '/exports-integration',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Message History", imageSrc:'./assets/images/MessageHistory.svg', routerLink: '/message-history',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Tutorials", imageSrc:'./assets/images/Tutorials_Secondary.svg', routerLink: '/tutorials',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true},
      {label: "Impersonate Contact", imageSrc:'./assets/images/MessageHistory.svg', routerLink: '/impersonate-contact',routerLinkActive:'router-link-active',routerLinkActiveOptions: { exact: true },expanded: true}
    //],
    //Contact: 'Contact Us',
    //Help: 'Need Help',
    //}
  ]

}

export const MailBoxStatus = {
  status : [
      {label: "emptyMailBox", imageSrc:'./assets/images/Email_Primary.svg',expanded: true},
      {label: "emptyMailBoxRed", imageSrc:'./assets/images/Email_Primary.svg',expanded: true},
      {label: "emptyMailBoxSecondary", imageSrc:'./assets/images/Email_Primary.svg',expanded: true},
      {label: "mailBoxFull", imageSrc:'./assets/images/Email_Enhanced.svg',expanded: true},
      {label: "mailBoxFullRed", imageSrc:'./assets/images/Email_Enhanced.svg',expanded: true},
      {label: "mailBoxFullSecondary", imageSrc:'./assets/images/Email_Enhanced.svg',expanded: true}
      ]

}

export const mailBoxIcon = {
  icons : [
      {label: "primaryMail", imageSrc:'./assets/images/Email_Primary.svg',expanded: true},  
      {label: "activeMail", imageSrc:'./assets/images/Email_White.svg',expanded: true},
      {label: "inActiveMail", imageSrc:'./assets/images/Email_Gray.svg',expanded: true},
      {label: "primaryMessage", imageSrc:'./assets/images/TextMessage_Primary.svg',expanded: true},
      {label: "activeMessage", imageSrc:'./assets/images/TextMessage_White.svg',expanded: true},
      {label: "inActiveMessage", imageSrc:'./assets/images/TextMessage_Gray.svg',expanded: true},
      ]

}

export const DateRange = {
  options:[
    "Custom Date Range",
    "Current Week",
    "Today",
    "Yesterday",
    "Current Month",
    "Last Month"
  ]
}

export const  eventTypes: any = {
  "Breakfast": {
    "class": "breakfast",
    "iconColor": "./assets/images/EventTypeBreakfast_Color.svg",
    "iconWhite": "./assets/images/EventTypeBreakfast_White.svg"
  },
  "Continental Breakfast": {
    "class": "continental-breakfast",
    "iconColor": "./assets/images/EventTypeContinentalBreakfast_Color.svg",
    "iconWhite": "./assets/images/EventTypeContinentalBreakfast_White.svg"
  },
  "Lunch": {
    "class": "lunch",
    "iconColor": "./assets/images/EventTypeLunch_Color.svg",
    "iconWhite": "./assets/images/EventTypeLunch_White.svg"
  },
  "Dinner": {
    "class": "dinner",
    "iconColor": "./assets/images/EventTypeDinner_Color.svg",
    "iconWhite": "./assets/images/EventTypeDinner_White.svg"
  },
  "Hors d'Oeuvres": {
    "class": "hors-doeuvres",
    "iconColor": "./assets/images/EventTypeHorsDoeuvres_Color.svg",
    "iconWhite": "./assets/images/EventTypeHorsDoeuvres_White.svg"
  },
  "Refreshments": {
    "class": "refreshments",
    "iconColor": "./assets/images/EventTypeRefreshments_Color.svg",
    "iconWhite": "./assets/images/EventTypeRefreshments_White.svg"
  },
  "Live Webinar": {
    "class": "live-webinar",
    "iconColor": "./assets/images/EventTypeLiveWebinar_Color.svg",
    "iconWhite": "./assets/images/EventTypeLiveWebinar_White.svg"
  },
  "Other": {
    "class": "other",
    "iconColor": "./assets/images/EventTypeOther_Color.svg",
    "iconWhite": "./assets/images/EventTypeOther_White.svg"
  }
}

export const iconImages: any = {
  "alert": {
    "primary": "Alert_Primary.svg"
  },
  "announcment": {
    "primary": "Announcement_Primary.svg"
  },
  "archive": {
    "primary": "Archive_Primary.svg"
  },
  "art": {
    "primary": "Art_Primary.svg"
  },
  "cart": {
    "primary": "Cart_Primary.svg"
  },
  "charts": {
    "primary": "Charts_Primary.svg"
  },
  "circleMinus": {
    "primary": "CircleMinus_Primary.svg"
  },
  "circlePlus": {
    "primary": "CirclePlus_Primary.svg"
  },
  "copy": {
    "primary": "Copy_Primary.svg"
  },
  "doNotEmail": {
    "primary": "DoNotEmail_Primary.svg"
  },
  "doNotMail": {
    "primary": "DoNotMail_Primary.svg"
  },
  "doNotPhone": {
    "primary": "DoNotPhone_Primary.svg"
  },
  "doNotText": {
    "primary": "DoNotText_Primary.svg"
  },
  "edit": {
    "primary": "Edit_Primary.svg"
  },
  "email": {
    "primary": "Email_Primary.svg"
  },
  "externalLink": {
    "primary": "ExternalLink_Primary.svg"
  },
  "eventAdd": {
    "primary": "EventAdd_Primary.svg"
  },
  "eventEdit": {
    "primary": "EventEdit_Primary.svg"
  },
  "info": {
    "primary": "Info_Primary.svg"
  },
  "invoice:": {
    "primary": "Invoice.svg"
  },
  "manageCampaigns": {
    "primary": "ManageCampaigns.svg"
  },
  "manageEvents": {
    "primary": "ManageEvents_Primary.svg"
  },
  "notes": {
    "primary": "Notes_Primary.svg"
  },
  "payment": {
    "primary": "Payment_Primary.svg"
  },
  "prospectMail": {
    "primary": "ProspectMail_Primary.svg"
  },
  "report": {
    "primary": "Report_Primary.svg"
  },
  "reports": {
    "primary": "Reports_Primary.svg"
  },
  "sourceClient": {
    "primary": "Source-Client_Primary.svg"
  },
  "sourceDigital": {
    "primary": "Source-Digital-std.svg"
  },
  "sourceDirectMail": {
    "primary": "Source-DirectMail-std.svg"
  },
  "sourceMassMedia": {
    "primary": "Source-MassMedia-std.svg"
  },
  "textMessage": {
    "primary": "TextMessage_Primary.svg"
  },
  "view": {
    "primary": "View_Primary.svg"
  }
}