import { Injectable } from '@angular/core';


@Injectable()
export class HeaderService {

  public _loadNumber: any;
  public headerString: string;

  /**
   * Default Constructer Invoked.
   */
  constructor() { }


  public setHeaderValue(headerPage) {
    this.headerString = headerPage;
  }

  public getHeaderValue() {
    return this.headerString;
  }

}