<!-- START PCNG 1051 Hub Session Logging Out -->
<p-dialog header="Header" [visible]="((oneLoginTimeOut) < 10 && (oneLoginTimeOut) > 0) && isModalOpened" (onHide)="isModalOpened=false" position="top" [baseZIndex]="11000" [modal]="true" [style]="{ width: '35vw' }" [draggable]="false" [resizable]="false" [closeOnEscape]="false" [closable]="false">
  <ng-template pTemplate="header">
    <h3>Session Expiring</h3>
</ng-template>
<div>Your OneLogin session has been open for 12 hours.  Your Hub session will close in 10 minutes.</div>
<div class="custom-message"> {{ displayTimer}} </div>
<ng-template pTemplate="footer">
    <div class="row session-logout-btn">
      <button pButton type="button" (click)="onDismiss()" class="dismiss-btn dismiss-btn-color"
          label="Dismiss"></button>
      <button pButton type="button" class="restart-hub-btn restart-hub-btncolor"
          (click)="onRestartHubNow()" label="Restart Hub Now"></button>
  </div>
</ng-template>
</p-dialog>

<!-- <p-dialog [visible]="((oneLoginTimeOut) <= 30000 && (oneLoginTimeOut) > 0)" position="bottom" (onHide)="onSignout()" [showHeader]="false" [draggable]="false" [closeOnEscape]="false" [style]="{width: '25vw'}" [closable]="false">
  <div class="custom-message"><span>You will be logged out in {{oneLoginTimeOut | date: 's' : 'UTC'}} seconds.</span> </div>
</p-dialog> -->
<!-- END PCNG 1051 Hub Session Logging Out -->
<router-outlet></router-outlet>
