import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { apiurlName, SharedService } from '../../shared/services/shared.service';

@Injectable({
  providedIn: 'root'
})
export class StandaloneCommunicationService {
  private baseAPIUrlSecurity = 'https://security-api.lrsphub.com/api/';
  private baseAPIUrl = '';

  constructor(private http: HttpClient, private sharedService: SharedService) {
    this.baseAPIUrl = sharedService.splitCurrentURL(apiurlName.dashboardProspectApiEndpoint)
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
  }

  fetchDecryption(v: string): Observable<any> {
    return this.http.get(this.baseAPIUrlSecurity + "decryption?request=" + v, { headers: this.httpOptions.headers }).pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  accessLeadDetails(leadId: string, pin: string): Observable<any> {
    const params = new HttpParams().set('leadId', leadId).set('pin', pin);
    const options = { headers: new HttpHeaders(), params: params };
    return this.http.get(this.baseAPIUrl + 'api/accessLeadDetails', options).pipe(
      map(data => data),
      catchError(this.handleError)
    );
  }

  private handleError(error: any): Observable<never> {
    console.error('An error occurred:', error);
    throw error;
  }
}
