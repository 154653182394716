import { UserManagerSettings } from "oidc-client";

export const environmentDev = {
  production: false,
  APP_BASE_URL:"./../../../assets/data/",
  dashboardProspectApiURL: 'https://dev-prospectapi.lrsphub.com/',
  contactApiURL:'https://dev-contactapi.lrsphub.com/',
  campaignApiURL: 'https://dev-campaignapi.lrsphub.com/',
  messageHistoryApiURL:'https://dev-messagehistoryapi.lrsphub.com/',  
  eventApiURL: 'https://dev-eventapi.lrsphub.com/',
  responsesettingEventEditApiURL:'https://dev-responsesettingsapi.lrsphub.com/',  
  //responsesettingEventEditApiURL:'https://dev-prospecteventeditapi.lrsphub.com/',  
  integrationExportsApiURL:'https://dev-exportsapi.lrsphub.com/',
  OneLoginApiURL:'https://olsso.lrsphub.com/',
  achSuiteletURL:'https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080_SB1&ns-at=AAEJ7tMQMVycW3-EnDdZvjnksiBEtaHZ4nfx6C-3EaRwviXN8q4&paytype=achresponse',
  achFormURL: 'https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080_SB1&ns-at=AAEJ7tMQMVycW3-EnDdZvjnksiBEtaHZ4nfx6C-3EaRwviXN8q4&paytype=customachform',
  hubCommunicationApis: 'http://dev-sendingmail-awsses-api.lrsphub.com/',
  uspsPostalStatement: 'https://uspscsvdata-nonprod.lrsphub.com/',
  authUserName:"admin",
  authPassword:"Rk2iK4tZm5I3",
  OneloginTimeout:'1800000',
};

export const clientSettingsDev: UserManagerSettings = {
  authority: 'https://leadingresponse.onelogin.com/oidc/2',
  client_id: '750e9000-bda7-013a-7b8c-029937692b75192627',
  redirect_uri: 'https://dev.lrsphub.com/auth-callback',
  post_logout_redirect_uri: 'https://leadingresponse.onelogin.com/login2',
  response_type:"id_token token",
  scope:"openid profile",
  filterProtocolClaims: true,
  loadUserInfo: true
}

export const PaymentURL:string="https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_lrpayprocess&deploy=customdeploy_lrpayprocess&compid=1014080_SB1&h=ed12ad571b8e6e4ab424&tranno=<Salesorderno#Invoice#>&trantype=4";