import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Router } from '@angular/router';
import { SharedService } from './shared/services/shared.service';
import { Idle } from 'idlejs/dist';
import { OneloginAuthService } from './shared/services/onelogin-auth.service';
import { map, Observable, takeWhile, timer } from 'rxjs';
import { AppConstants } from '../app/shared/constants/app.constants'
import * as moment from 'moment';
import { SalesOrderService } from './layout/sales-order/sales-order.service';
import { MessageHistoryService } from './layout/message-history/message-history.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public userDetails: any = [];
    public userRoles: any = [];
    public role: string = "";
    public sessionTimeoutFlag: boolean = false;
    public screenFlagNormal: boolean = false;
    public screenFlagDesktop: boolean = false;

    public oneLoginTimeOut: number;
    public timeRemaining$: Observable<Number>;
    public unscbscribeTime$: any;
    public isModalOpened = false;
    public displayTimer: any;
    public timer: NodeJS.Timer;
    expiretime: any;
    expiretimeTimeInSeconds: any;
    timerCount: number;
    sessionTimeOutTimer: NodeJS.Timer;

    constructor(private authService: AuthService, private router: Router, private salesOrderService: SalesOrderService, private messageHistoryService: MessageHistoryService, private sharedService: SharedService, private oneLoginAuthService: OneloginAuthService) {
    }
    // START PCNG 1051 Hub Session Logging Out

    onRestartHubNow() {
        this.isModalOpened = false;
        console.log(this.router.url);
        sessionStorage.setItem('restarthubnow', this.router.url);
        this.oneLoginAuthService.startAuthentication();
    }
    async onSignout() {
        this.isModalOpened = false;
        if ((await this.oneLoginAuthService?.getUser())?.expired) {
            this.oneLoginAuthService.signout();
        }
        else { // if user is not expired check again after 10 seconds and then logout
            setTimeout(() => {
                //   if((await this.oneLoginAuthService?.getUser())?.expired) {
                this.oneLoginAuthService.signout();
                //   }
            }, 1000)
        }
    }
    // END PCNG 1051 Hub Session Logging Out
    ngOnInit() {
        this.calculateScreenDimensions();
        this.checkIdleSession();
        this.getConfigKeyValuePairs();
        this.getGlobalKeyforHubConnect();
        this.getGlobalFeatureKeys();
        //this.authService.hasRoleName();
        // START PCNG 1051 Hub Session Logging Out
        // setTimeout(() => {
        //     this.oneLoginAuthService?.getUser()?.then(user => {
        //         if (user !== null) {
        //             let expiretime = user?.expires_in;
        //             let modaltimeout = AppConstants.HUB_SESSION_TIMEOUT;
        //             //  alert(expiretime);
        //             if (expiretime > AppConstants.HUB_SESSION_TIMEOUT) {
        //                 expiretime = (expiretime - AppConstants.HUB_SESSION_TIMEOUT) * 1000;
        //             } else {
        //                 modaltimeout = expiretime;
        //                 expiretime = 0;
        //             }

        //             // timer will start <= 10min session expire
        //             this.timeRemaining$ = timer(expiretime, 1000).pipe(
        //                 map(n => (modaltimeout - n) * 1000),
        //                 takeWhile(n => n >= 0),
        //             );
        //             this.isModalOpened = true;
        //             this.unscbscribeTime$ = this.timeRemaining$.subscribe((data) => {
        //                 // onelogin session expire - system/desktop time (convert to milliseconds)
        //                 this.oneLoginTimeOut = (user.expires_at - moment().unix()) * 1000;
        //                 if (this.oneLoginTimeOut <= 0) {
        //                     this.unscbscribeTime$.unsubscribe();
        //                 }
        //             });
        //         }
        //     })
        // }, 5000)
        // END PCNG 1051 Hub Session Logging Out
    }

    onDismiss() {
        clearInterval(this.timer);
        clearInterval(this.sessionTimeOutTimer)
        this.oneLoginTimeOut = 0;
        this.displayTimer = 0
        this.isModalOpened = false;
        this.expiretime = 3;
        this.expiretimeTimeInSeconds = 0;
        this.timerCount = 0;
    }

    checkIdleSession() {

        this.sessionTimeOutTimer = setInterval(() => {
            this.expiretime = 10;
            this.expiretimeTimeInSeconds = this.expiretime * 60;
            this.timerCount = 0;
            let statSecond: number = 60;
            const prefix = this.expiretime < 10 ? "0" : "";
            this.timer = setInterval(() => {
                this.isModalOpened = true
                this.expiretimeTimeInSeconds--;
                if (statSecond != 0) statSecond--;
                else statSecond = 59;

                if (statSecond < 10) {
                    this.timerCount = 0 + statSecond;
                } else this.timerCount = statSecond;
                let temp = this.expiretimeTimeInSeconds;
                this.displayTimer = `${prefix}${Math.floor(temp / 60)}:${this.timerCount}`;
                this.oneLoginTimeOut = parseInt(this.displayTimer);
                if (this.expiretimeTimeInSeconds == 0) {
                    this.isModalOpened = false
                    clearInterval(this.timer);
                    this.oneLoginAuthService.signout();
                }
            }, 1000);
        }, 41400000);
            //41400000
        setInterval(() => {
            console.log('IDLE');
        }, 500000);

        const idle = new Idle().whenNotInteractive().within(30).do(() => {
            console.log('IDLE');
            this.sessionTimeoutFlag = true;
        }).start();
    }

    /**
    * Function For Called When Component class initialized.
    */
    calculateScreenDimensions() {
        const innerWidth = window.innerWidth;
        if (innerWidth === 1920) {
            this.screenFlagNormal = true;
        } else {
            this.screenFlagNormal = false;
        }

        if (innerWidth >= 1300 && innerWidth < 1500) {
            this.screenFlagDesktop = true;
        } else {
            this.screenFlagDesktop = false;
        }
    }
    getConfigKeyValuePairs() {
        this.salesOrderService.getConfigKeyValuePairs().subscribe(
            (data: any) => {
                data.forEach(item => {
                    if (item.globalKeyName === 'SOPaginationRows') sessionStorage.setItem("SOPaginationRows", item.globalKeyValue);
                    if (item.globalKeyName === 'HubConnectPaginationRows') sessionStorage.setItem("HubConnectPaginationRows", item.globalKeyValue);
                });
            },
            error => {
                console.log(error);
            });
    }

    getGlobalKeyforHubConnect() {
        this.messageHistoryService.getConfigKeyValuePairs('DirectCommunicationIcon', 'HUB').subscribe((data: any) => {
            if (data.length > 0) {
                if (data[0].globalKeyName == 'HubConnect' && data[0].globalKeyValue == 'NO') {
                    sessionStorage.setItem("HubConnect", "false");
                } else {
                    sessionStorage.setItem("HubConnect", "true");
                }    
            }
            else sessionStorage.setItem("HubConnect", "false");
        });
    }


    /**
     * Retrieves global feature keys and stores them in session storage.
     */
    getGlobalFeatureKeys() {
        
        let archiving = "false";
        let searchNotes = "false";
        let searchVenue = "false";
        let hubNewsUpdateDate = "";
        let tutorialVideo = "false";

        this.messageHistoryService.getConfigKeyValuePairs('HomePage', 'HUB').subscribe((data: any) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].globalKeyName == 'Archiving') {
                    archiving = data[i].globalKeyValue ? "true" : "false";
                }
                if (data[i].globalKeyName == 'SearchNote') {
                    searchNotes = data[i].globalKeyValue === 'YES' ? 'true' : 'false';
                }
                if (data[i].globalKeyName == 'SearchVenue') {
                    searchVenue = data[i].globalKeyValue === 'YES' ? 'true' : 'false';
                }
                if (data[i].globalKeyName == 'NewsUpdated') {
                    hubNewsUpdateDate = data[i].globalKeyValue ?  data[i].globalKeyValue : hubNewsUpdateDate;
                }
                if (data[i].globalKeyName == 'GlobalTutorial') {
                    tutorialVideo = data[i].globalKeyValue === 'YES' ? 'true' : 'false';
                }
            }
            sessionStorage.setItem("Archiving", archiving);
            sessionStorage.setItem("SearchNotes", searchNotes);
            sessionStorage.setItem("SearchVenue", searchVenue);
            sessionStorage.setItem("HubNewsUpdateDate", hubNewsUpdateDate);
            sessionStorage.setItem("TutorialVideo", tutorialVideo);
        });

    }

    
    ngOnDestroy() {
        this.unscbscribeTime$.unsubscribe();
    }
}
