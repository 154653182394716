import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, pipe, retry, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { apiurlName, SharedService } from '../../shared/services/shared.service';
import { DropDown, MessageData } from './../../../assets/model/message';


let httpresponseStatus: number;
//const messagehistoryapiendpoint = environment.messagehistoryapiendpoint;
@Injectable({
  providedIn: 'root'
})

export class MessageHistoryService {
  public MessageHistorydata: MessageData[];
  public MessageDropdowndata: DropDown[];

  private baseAPIurl = '';
  private baseAPIurl1 = '';
  // Default constructor invoked
  constructor(private http: HttpClient, private sharedService: SharedService){
    this.baseAPIurl = sharedService.splitCurrentURL(apiurlName.messagehistoryApiEndpoint)
    this.baseAPIurl1 = sharedService.splitCurrentURL(apiurlName.dashboardProspectApiEndpoint)
  }
  //customerId = { customerId: sessionStorage.getItem('customerid')}

  //Function call for Your Notification Details
  // getYourNotificationsDetails(): Observable<any> {
  //   let custId=this.customerId.customerId;
  //   return this.http.get(this.baseAPIurl + 'api/message/' +custId, {observe: 'response'
  //   }).pipe(map(data => {
  //     httpresponseStatus = data.status
  //     return data.body
  //   }, catchError(this.handleError)
  //   ));
  // }

  getYourNotificationsDetails(CustomerEmail:string,FromDate,ToDate): Observable<any> {
    const param = new HttpParams()
    .set('CustomerEmail', CustomerEmail)
    .set('FromDate', FromDate)
    .set('ToDate', ToDate);

    //let custId=this.customerId.customerId;
    return this.http.get(this.baseAPIurl + `api/message/`, {observe: 'response',params:param
    }).pipe(map(data => {
      httpresponseStatus = data.status
      return data.body
    }, catchError(this.handleError)
    ));
  }

  getVenueNotificationDetails(CustomerEmail:string,FromDate,ToDate): Observable<any> {
    const param = new HttpParams()
    .set('CustomerEmail', CustomerEmail)
    .set('FromDate', FromDate)
    .set('ToDate', ToDate);

    //let custId=this.customerId.customerId;
    return this.http.get(this.baseAPIurl + `api/Venuemessage/`, {observe: 'response',params:param
    }).pipe(map(data => {
      httpresponseStatus = data.status
      return data.body
    }, catchError(this.handleError)
    ));
  }
  public getConfigKeyValuePairs(paramApplicationKey = null,paramApplicationID = null):Observable<any>{
    var applicationKey = 'DateRange';
    var applicationID = 'HUB';
    if(paramApplicationKey && paramApplicationID){
      applicationKey = paramApplicationKey;
    applicationID = paramApplicationID;
    }
    const param = new HttpParams()   
    .set('ApplicationKey',applicationKey)    
    .set('ApplicationID',applicationID) 
      return this.http.get(this.baseAPIurl1 + `api/prospect/GetglobalKeyvalue`, {observe: 'response',params:param}).pipe(map(data => {                      
       return data.body},catchError(this.handleError)             
    )); 
  }
  //Function call for Prospect Notification Details
  // getProspectNotificationsDetails(): Observable<any> {
  //   let custId=this.customerId.customerId;
  //   //let prosId=this.prospectId.prospectId;
  //   return this.http.get(this.baseAPIurl + 'api/message/'+custId , { observe: 'response'
  //   }).pipe(map(data => {
  //     httpresponseStatus = data.status
  //     return data.body
  //   }, catchError(this.handleError)
  //   ));
  // }

  // getProspectNotificationsDetails(customerId:Number,invoice:string,salesOrderNumber:string): Observable<any> {
  //   //let custId=this.customerId.customerId;
  //   //let prosId=this.prospectId.prospectId;
  //   const param = new HttpParams()
  //   .set('invoice', invoice==undefined || invoice==null || invoice==''?null:invoice)
  //   .set('salesOrderNumber', salesOrderNumber==undefined || salesOrderNumber==null || salesOrderNumber==''?null:salesOrderNumber);
  //   return this.http.get(this.baseAPIurl + `api/message/${customerId}`, { observe: 'response',params:param
  //   }).pipe(map(data => {
  //     httpresponseStatus = data.status
  //     return data.body
  //   }, catchError(this.handleError)
  //   ));
  // }

  // getProspectAutoResonderDetails(): Observable<any> {
  //   let custId=this.customerId.customerId;
  //   //let prosId=this.prospectId.prospectId;
  //   return this.http.get(this.baseAPIurl + 'api/leadmessage/'+custId , {observe: 'response'
  //   }).pipe(map(data => {
  //     httpresponseStatus = data.status
  //     return data.body
  //   }, catchError(this.handleError)
  //   ));
  // }

  getProspectAutoResonderDetails(CustomerId:string, FromDate:string,ToDate:number): Observable<any> {
    const param = new HttpParams()
    .set('CustomerId', CustomerId)
    .set('FromDate', FromDate)
    .set('ToDate', ToDate);
    return this.http.get(this.baseAPIurl + `api/leadmessage/` , {observe: 'response',params:param
    }).pipe(map(data => {
      httpresponseStatus = data.status
      return data.body
    }, catchError(this.handleError)
    ));
  }
  getProspectResonder(customerNumber:string,prospectId:number): Observable<any> {
    return this.http.get(this.baseAPIurl + 'api/message/'+customerNumber+'/'+prospectId ,{observe: 'response'}).pipe(map(data => {
      httpresponseStatus = data.status
      return data.body
    }, catchError(this.handleError)
    ));
  }
  // getProspectResonderText(customeid:number): Observable<any> {
  //   return this.http.get(this.baseAPIurl + 'api/leadmessage/'+customeid,{observe: 'response'}).pipe(map(data => {
  //     httpresponseStatus = data.status
  //     return data.body
  //   }, catchError(this.handleError)
  //   ));
  // }

  public weeklist: DropDown[];
    private weekapi = "http://dashboard1apidev-env.eba-rywhtwbq.us-east-1.elasticbeanstalk.com/api/dashboard/week";
    // Function call for Timeframe
     getTimeframes():Observable<any>{
      return this.http.get(this.baseAPIurl1 + 'api/dashboard/week',{observe: 'response'}).pipe(map(data => {                      
                      httpresponseStatus = data.status
                       return data.body},catchError(this.handleError)               
            ));      
    }

  public getAllDropdownStatus(): Observable<DropDown[]> {
    return this.http.get<DropDown[]>("../../../../assets/data/dropdownstatusdata.json")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  public getAllDropdowncap(): Observable<DropDown[]> {
    return this.http.get<DropDown[]>("../../../../assets/data/dropdown_campaign.json")
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }
  // Error handling 
  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

}






