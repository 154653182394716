import { PCChartsModule } from './shared/modules/charts/charts.module';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './shared';
import { SharedService } from './shared/services/shared.service';
import { PageLoaderModule } from './shared/modules/loader/page-loader.module';
import { AuthService } from './shared/services/auth.service';
import { DialogModule } from 'primeng/dialog';
import { HeaderService } from './layout/components/header/header.service';
import {CalendarModule} from 'primeng/calendar';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from "primeng/menubar";
import { MenuModule } from 'primeng/menu';
import { RippleModule } from 'primeng/ripple';
import {ChartModule} from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { InterceptorService } from './shared/modules/interceptor/interceptor.service';
import { TabViewModule } from 'primeng/tabview';
import { PanelModule } from 'primeng/panel';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        PageLoaderModule,
        DialogModule,
        AppRoutingModule,
        CalendarModule,
        SidebarModule,
        MenubarModule,
        MenuModule,
        RippleModule,
        ChartModule,
        TableModule,
        TabViewModule,
        PanelModule,
        DropdownModule, PCChartsModule,RadioButtonModule,FormsModule
    ],
    declarations: [AppComponent],
    providers: [AuthService,AuthGuard,SharedService,HeaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true
        }],
    bootstrap: [AppComponent]
})
export class AppModule {}
