export const CHART_DATA_OPTIONS = {
    datasets: [{
      data: [33, 66, 100],
      value: 0,
      backgroundColor: ["rgb(238,59,59)", "rgb(238,127,59)", "rgb(110,197,119)"],
      borderWidth: 5,
    }]
  }
  
export const CHART_OPTIONS = {
    responsive: true,
    cutoutPercentage: 35,
    layout: {
      padding: {
        bottom: 30
      }
    },
    needle: {
      radiusPercentage: 2,
      widthPercentage: 4,
      lengthPercentage: 70,
      color: 'rgb(26, 73, 92)',
    },
    valueLabel: {
      display: false
    }
}