import { Component, Input, OnChanges } from '@angular/core';
import { eventTypes } from '../../../../shared/constants/app.constants';

@Component({
  selector: 'app-event-header',
  templateUrl: './event-header.component.html',
  styleUrls: ['./event-header.component.scss']
})

export class EventHeaderComponent implements OnChanges {

  @Input() event: any = {}; // Event data
  @Input() largeSize: boolean = false; // Large size flag
  @Input() showDateCard: boolean = false; // Show date card flag
  @Input() showCampaignData: boolean = false; // Show campaign data flag (campaign title and advisor name)
  @Input() showMeals: boolean = false; // Show meals flag
  @Input() showLinks: boolean = true; // Show links flag

  public eventClass: string = ''; // Event CSS class
  public eventIcon: string = ''; // Event icon path

  constructor() { }

  ngOnChanges(): void {

    // Normalize data from different sources
    if (this.event.eventtype) this.event.eventType = this.event.eventtype;
    if (this.event.address1) this.event.addressLine1 = this.event.address1;
    if (this.event.stateOrProvince) {
      if (this.event.stateOrProvince.stateName) this.event.state = this.event.stateOrProvince.stateName;
    }

    // Get CSS class and icon path based on event type
    if (this.event.eventType && eventTypes[this.event.eventType]) {
      this.eventClass = eventTypes[this.event.eventType].class;
      this.eventIcon = eventTypes[this.event.eventType].iconWhite;
    } else {
      this.eventClass = eventTypes["Other"].class;
      this.eventIcon = eventTypes["Other"].iconWhite;
    }


  }

}
