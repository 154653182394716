import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConstants } from '../../constants/app.constants';
@Component({
    selector: 'app-page-loader',
    templateUrl: './page-loader.component.html',
    styleUrls: ['./page-loader.component.scss']
})
export class PageLoaderComponent implements OnInit {
    @Input() showLoader: boolean;
    constructor(private spinnerService: NgxSpinnerService) {       
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if(this.showLoader) { 
            this.spinnerService.show();
        } else {
            this.spinnerService.hide();
        }
      }
      
    ngOnInit() { 
    }
}
