<div [ngClass]="brandClass">
    <div class="screen-wrapper">
        <div class="questionnaire">
            <div class="questionnaire__disclaimer informational informational--basic">
                <div class="informational__icon-wrapper">
                    <img class="informational__icon" src="./assets/images/Alert_Primary.svg" title="Note">
                </div>
                <div class="informational__content">
                    The user acknowledges that the data included in this document may contain confidential
                    information disclosed voluntarily by the consumer. The user acknowledges that the
                    information contained in this document must be kept in strict confidence with limited
                    usage for the purpose of case evaluation. The user is prohibited from disclosing this
                    data, including but not limited to, aggregating, renting, selling, or sharing the data
                    to third parties without the expressed written consent of the consumer.
                </div>
            </div>
            <div class="questionnaire__function">
                <button  type="button" class="button button--primary" id="print-questionnaire"
                    label="Print Questionnaire" (click)="pdf.saveAs('lead-info-' + leadData.referralDate + '.pdf')">Download PDF for Print</button>
            </div>
            <div class="questionnaire__info">
                <!-- <div class="questionnaire__field questionnaire__field--client">
                    <div class="questionnaire__label">Hub Customer:</div>
                    <div class="questionnaire__value">{{username}}</div>
                </div> -->
                <div class="questionnaire__field questionnaire__field--pa">
                    <div class="questionnaire__label">Practice Area:</div>
                    <div class="questionnaire__value">{{leadData.practiceArea}}</div>
                </div>
                <div class="questionnaire__field questionnaire__field--route">
                    <div class="questionnaire__label">Referral Route:</div>
                    <div class="questionnaire__value">{{leadData.referralRoute}}</div>
                </div>
                <div class="questionnaire__field questionnaire__field--date">
                    <div class="questionnaire__label">Referral Date:</div>
                    <div class="questionnaire__value">{{leadData.referralDate | date: 'MM/dd/yy h:mm a' }}</div>
                </div>
                <div class="questionnaire__field questionnaire__field--name">
                    <div class="questionnaire__label">Name:</div>
                    <div class="questionnaire__value">{{leadData.name}}</div>
                </div>
                <div class="questionnaire__field questionnaire__field--phone">
                    <div class="questionnaire__label">Phone:</div>
                    <div class="questionnaire__value">{{leadData.phoneNumber}} </div>
                </div>
                <div class="questionnaire__field questionnaire__field--geo">
                    <div class="questionnaire__label">City/State/Zip:</div>
                    <div class="questionnaire__value">{{leadData.city}}, {{leadData.state}}
                        {{leadData.zip}}</div>
                </div>
                <div class="questionnaire__field questionnaire__field--email">
                    <div class="questionnaire__label">Email:</div>
                    <div class="questionnaire__value">{{leadData.email}}</div>
                </div>
            </div>
            <div class="questionnaire__survey">
                <div class="questionnaire__survey-head">
                    <div>Question</div>
                    <div>Prospect Response</div>
                </div>
                <div *ngFor="let items of leadData.questionnaires" class="questionnaire__survey-row">
                    <div class="questionnaire__label">{{items.question}}</div>
                    <div class="questionnaire__value">{{items.prospectResponse}}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="pdf-wrapper">
        <kendo-pdf-export #pdf [autoPrint]="autoPrint" paperSize="A4" margin="2cm">
            <div class="questionnaire">
                <div class="questionnaire__disclaimer informational informational--basic">
                    <div class="informational__icon-wrapper">
                        <img class="informational__icon" src="./assets/images/Alert_Primary.svg" title="Note">
                    </div>
                    <div class="informational__content">
                        The user acknowledges that the data included in this document may contain confidential
                        information disclosed voluntarily by the consumer. The user acknowledges that the
                        information contained in this document must be kept in strict confidence with limited
                        usage for the purpose of case evaluation. The user is prohibited from disclosing this
                        data, including but not limited to, aggregating, renting, selling, or sharing the data
                        to third parties without the expressed written consent of the consumer.
                    </div>
                </div>
                <div class="questionnaire__info">
                    <!-- <div class="questionnaire__field questionnaire__field--client">
                        <div class="questionnaire__label">Hub Customer:</div>
                        <div class="questionnaire__value">{{username}}</div>
                    </div> -->
                    <div class="questionnaire__field questionnaire__field--pa">
                        <div class="questionnaire__label">Practice Area:</div>
                        <div class="questionnaire__value">{{leadData.practiceArea}}</div>
                    </div>
                    <div class="questionnaire__field questionnaire__field--route">
                        <div class="questionnaire__label">Referral Route:</div>
                        <div class="questionnaire__value">{{leadData.referralRoute}}</div>
                    </div>
                    <div class="questionnaire__field questionnaire__field--date">
                        <div class="questionnaire__label">Referral Date:</div>
                        <div class="questionnaire__value">{{leadData.referralDate | date: 'MM/dd/yy h:mm a' }}</div>
                    </div>
                    <div class="questionnaire__field questionnaire__field--name">
                        <div class="questionnaire__label">Name:</div>
                        <div class="questionnaire__value">{{leadData.name}}</div>
                    </div>
                    <div class="questionnaire__field questionnaire__field--phone">
                        <div class="questionnaire__label">Phone:</div>
                        <div class="questionnaire__value">{{leadData.phoneNumber}} </div>
                    </div>
                    <div class="questionnaire__field questionnaire__field--geo">
                        <div class="questionnaire__label">City/State/Zip:</div>
                        <div class="questionnaire__value">{{leadData.city}}, {{leadData.state}}
                            {{leadData.zip}}</div>
                    </div>
                    <div class="questionnaire__field questionnaire__field--email">
                        <div class="questionnaire__label">Email:</div>
                        <div class="questionnaire__value">{{leadData.email}}</div>
                    </div>
                </div>
                <div class="questionnaire__survey">
                    <div class="questionnaire__survey-head">
                        <div>Question</div>
                        <div>Prospect Response</div>
                    </div>
                    <div *ngFor="let items of leadData.questionnaires" class="questionnaire__survey-row">
                        <div class="questionnaire__label">{{items.question}}</div>
                        <div class="questionnaire__value">{{items.prospectResponse}}</div>
                    </div>
                </div>
                <!-- <div class="questionnaire__function">
                    <button  type="button" class="button button--primary" id="print-questionnaire"
                        label="Print Questionnaire" (click)="pdf.saveAs('image-resolution.pdf')">Print Questionnaire</button>
                </div> -->
            </div>
        </kendo-pdf-export>
    </div>
</div>