import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Eventservice as EventService } from './notes-manager-dialog.service';
import { formatDate } from '@angular/common';
import { MessageService } from 'primeng/api';
import { Leadservice } from '../../../prospects/prospects.service';

@Component({
  selector: 'app-notes-manager-dialog',
  templateUrl: './notes-manager-dialog.component.html',
  styleUrls: ['./notes-manager-dialog.component.scss'],
  providers: [MessageService]
})
export class NotesManagerDialogComponent implements OnInit {

  @Input() prospectID: string = "";
  @Input() prospectName: string = "";
  @Input() showDialog: boolean = false;
  @Input() isLeadProspect: boolean = false;

  @Output() closePopup = new EventEmitter<{refreshData:any, closeNotePopup: boolean}>();

  prospectData: any = null;
  notes: any = null;
  leadData: any = null;

  showLoader: boolean = false;
  showConfirmation: boolean = false;
  showAddNote: boolean = true;
  addNoteContent: string = '';
  noteToDelete: number = -1;
  locale = 'en-US';
  IsNoteExists: boolean = false;

  changesMade: boolean = false;

  // Branding for whitelabeling
  public brand: string = 'hub'; // Brand for whitelabeling
  public brandClass: string = 'brand--hub'; // Brand class for whitelabeling
  
  constructor(private eventService: EventService, private messageService: MessageService, private leadService: Leadservice) { }

  ngOnInit(): void {
    this.getProspectInformation(this.prospectID);
    // Set the brand and brand class for whitelabeling
    if (sessionStorage.getItem('brand') !== '') {
      this.brand = sessionStorage.getItem('brand');
      this.brandClass = 'brand--' + this.brand;
    };      
  }

  getProspectInformation(prospectID) {
    this.showLoader = true;
    if(this.isLeadProspect) {
      this.leadService.getLeadInfoByLeadID(prospectID).subscribe((data: any) => {
        this.leadData = data;
        if (this.leadData.leadNoteDetails.length > 0) {
          this.IsNoteExists = true;
        }
        else {
          this.IsNoteExists = false;
        }
        this.notes = this.leadData.leadNoteDetails; 
        this.notes = this.notes.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
        this.notes.forEach(element => {
          let dateString = element.dateCreated + ' UTC';
          let yourDate = new Date(dateString);
          element.dateCreated = yourDate.toString();
        });
        this.showLoader = false;
      });
    }
    else {
      this.eventService.getresponderview(prospectID).subscribe((data: any[]) => {
        this.prospectData= data;
        this.prospectData.forEach(x => {
          if (x.notesDetails.length > 0) {
            this.IsNoteExists = true;
          }
          else {
            this.IsNoteExists = false;
          }
        })
        this.notes = this.prospectData.filter((x) => x.responderid === prospectID)[0].notesDetails;
        this.notes = this.notes.sort((a, b) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
        this.notes.forEach(element => {
          let dateString = element.dateCreated + ' UTC';
          let yourDate = new Date(dateString);
          element.dateCreated = yourDate.toString();
        });
        this.showLoader = false;
      });
    }
  }

  deleteNote(noteID) {
    this.noteToDelete = noteID;
    this.showConfirmation = true;
  }

  cancelDeleteNote() {
    this.showConfirmation = false;
  }

  confirmDeleteNote() {
    this.showLoader = true;
    this.showConfirmation = false;
    let today = new Date();
    let currentTime = formatDate(today, 'yyyy-MM-dd HH:mm:ss', this.locale);
    
    if(this.isLeadProspect) {
      this.leadService.updateLeadNotes("updateleadnotes", this.noteToDelete, this.prospectID, "", true, currentTime)
        .subscribe(
          (data) => {
            // Reload notes with this.getProspectInformation when delete is complete
            this.getProspectInformation(this.prospectID);

            this.changesMade = true;
            this.showLoader = false;
          },
          (error) => {
            console.error('Error deleting note:', error);
            this.showLoader = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
          }
      );
    }
    else {
      this.eventService.updateNotes("updatenotes", this.noteToDelete, this.prospectID, "", true, currentTime)
        .subscribe(
          (data) => {
            // Reload notes with this.getProspectInformation when delete is complete
            this.getProspectInformation(this.prospectID);

            this.changesMade = true;
            this.showLoader = false;
          },
          (error) => {
            console.error('Error deleting note:', error);
            this.showLoader = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
          }
      );
    }
  }

  addNote() {
    this.showAddNote = true;
  }

  saveNote() {
    let today = new Date();
    let currentTime = formatDate(today, 'yyyy-MM-dd HH:mm:ss', this.locale);

    if (this.addNoteContent.length > 0) {
      this.showLoader = true;

      if(this.isLeadProspect) {
        this.leadService.updateLeadNotes("updateleadnotes", 0, this.prospectID, this.addNoteContent, false, currentTime)
        .subscribe(
          (data) => {
            // Reload notes with this.getProspectInformation when save is complete
            this.getProspectInformation(this.prospectID);
            this.changesMade = true;
            this.showAddNote = false;
            this.showLoader = false;
            this.addNoteContent = "";
          },
          (error) => {
            console.error('Error saving note:', error);
            this.showLoader = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
          }
        );
      }
      else {
        this.eventService.updateNotes("updatenotes", 0, this.prospectID, this.addNoteContent, false, currentTime)
        .subscribe(
          (data) => {
            // Reload notes with this.getProspectInformation when save is complete
            this.getProspectInformation(this.prospectID);
            this.changesMade = true;
            this.showAddNote = false;
            this.showLoader = false;
            this.addNoteContent = "";
          },
          (error) => {
            console.error('Error saving note:', error);
            this.showLoader = false;
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'An error occurred' });
          }
        );
      }
    }
  }

  cancelNote() {
    this.showAddNote = false;
  }

  closeDialog() {
    this.showDialog = false;
    this.closePopup.emit({refreshData: this.IsNoteExists, closeNotePopup: false});
  }
}
