import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as jQuery from "jquery";
(window as any).$ = (window as any).jQuery = jQuery;
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import "./polyfills";
import { GaugesModule } from "@progress/kendo-angular-gauges";

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));
