import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-prospect-questionnaire',
  templateUrl: './prospect-questionnaire.component.html',
  styleUrls: ['./prospect-questionnaire.component.scss'],
  providers: [DatePipe]
})
export class ProspectQuestionnaireComponent implements OnInit {
  @Input() leadData: any;
  
  public autoPrint = true;

  public pdfName: string = "";

  // Branding for whitelabeling
  public brand: string = 'hub'; // Brand for whitelabeling
  public brandClass: string = 'brand--hub'; // Brand class for whitelabeling
  
  username: any;

  constructor() {}

  ngOnInit() {
    // Set the brand and brand class for whitelabeling
    if (sessionStorage.getItem('brand') !== '') {
      this.brand = sessionStorage.getItem('brand');
      this.brandClass = 'brand--' + this.brand;
    };      
  }
}
