import { Injectable } from '@angular/core';
import { debug } from 'console';
import { UserManager, UserManagerSettings, User } from "oidc-client";
import { SharedService } from './shared.service';
import { Observable, of } from 'rxjs';

// const clientSettings: UserManagerSettings = {
//   authority: 'https://leadingresponse.onelogin.com/oidc/2',
//   client_id: '750e9000-bda7-013a-7b8c-029937692b75192627',
//   redirect_uri: 'https://qa.lrsphub.com/auth-callback',
//   post_logout_redirect_uri: 'https://qa.lrsphub.com/',
//   response_type:"id_token token",
//   scope:"openid profile",
//   filterProtocolClaims: true,
//   loadUserInfo: true
// }

@Injectable({
  providedIn: 'root'
})
export class OneloginAuthService {
  private manager: UserManager;// = new UserManager(clientSettings)
  private user!: User
  
  constructor(private sharedService:SharedService) { 
    this.manager=new UserManager(sharedService.detectEnv_ParamOneLogin());
    
    this.manager.getUser().then(user => {
      if(user) {
        this.user = user
      }
    })
  }

  isLoggedIn() {
    return this.user && !this.user.expired
  }
  
  // START PCNG 1051 Hub Session Logging Out
    async getUser(): Promise<User> {
    return await this.manager.getUser();
  }
  // END PCNG 1051 Hub Session Logging Out
  getProfile(): any {
    return this.user.profile
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`
  }

  startAuthentication(): Promise<void> {
    return this.manager.signinRedirect()
  }

  completeAuthentication(): Promise<void> {
    return this.manager.signinRedirectCallback().then(user => {
      this.user = user;
    })
  }
  signout():Promise<void>{
    return  this.manager
    .signoutRedirect()
    .then(res => {     
    })
  }

}
