// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { UserManagerSettings } from "oidc-client";

export const environment = {
  production: false,
  APP_BASE_URL: "./../../../assets/data/",
  dashboardProspectApiURL: 'https://qa-prospectapi.lrsphub.com/',
  contactApiURL: 'https://qa-contactapi.lrsphub.com/',
  campaignApiURL: 'https://qa-campaignapi.lrsphub.com/',
  messageHistoryApiURL: 'https://qa-messagehistoryapi.lrsphub.com/',
  eventApiURL: 'https://qa-eventapi.lrsphub.com/',
  responsesettingEventEditApiURL: 'https://qa-responsesettingsapi.lrsphub.com/',
  integrationExportsApiURL: 'https://qa-integrationandexportsapi.lrsphub.com/',
  OneLoginApiURL: 'https://qa-Oneloginssoenv.lrsphub.com/',
  achSuiteletURL: 'https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080_SB1&ns-at=AAEJ7tMQMVycW3-EnDdZvjnksiBEtaHZ4nfx6C-3EaRwviXN8q4&paytype=achresponse',
  achFormURL: 'https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_cardxpayment_sl&deploy=customdeploy_cardxpayment_sl&compid=1014080_SB1&ns-at=AAEJ7tMQMVycW3-EnDdZvjnksiBEtaHZ4nfx6C-3EaRwviXN8q4&paytype=customachform',
  uspsPostalStatement: 'https://uspscsvdata-nonprod.lrsphub.com/',
  hubCommunicationApis: 'http://qa-sendingmail-awsses-api.lrsphub.com/',
  authUserName: "admin",
  authPassword: "Rk2iK4tZm5I3",
  OneloginTimeout: '1800000',
};

export const clientSettings: UserManagerSettings = {
  authority: 'https://leadingresponse.onelogin.com/oidc/2',
  client_id: '750e9000-bda7-013a-7b8c-029937692b75192627',
  redirect_uri: 'http://localhost:4200/auth-callback',
  post_logout_redirect_uri: 'https://leadingresponse.onelogin.com/login2',
  response_type: "id_token token",
  scope: "openid profile",
  filterProtocolClaims: true,
  loadUserInfo: true
}

export const PaymentURL: string = "https://1014080-sb1.extforms.netsuite.com/app/site/hosting/scriptlet.nl?script=customscript_lrpayprocess&deploy=customdeploy_lrpayprocess&compid=1014080_SB1&h=ed12ad571b8e6e4ab424&tranno=<Salesorderno#Invoice#>&trantype=4";