import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError} from 'rxjs';
import { DropDown } from '../../../assets/model/message';
import { SalesOrder } from '../../../assets/model/SalesOrder';
import { ACH_Charge_Details, ACH_Form_Details } from '../../../assets/model/ACHPaymentDetails';
import { SendLeadMailRes } from '../../../assets/model/SendLeadMailResult';
import { apiurlName, SharedService } from '../../shared/services/shared.service';

let httpresponseStatus:number;
@Injectable({
  providedIn: 'root'
})

export class SalesOrderService {

  public salesOrderDetails:SalesOrder[];
  private baseAPIurl = '';
  private baseAPIurl2 = '';
  // Default constructor invoked
  constructor(private http: HttpClient, private sharedService: SharedService){
    this.baseAPIurl = sharedService.splitCurrentURL(apiurlName.dashboardProspectApiEndpoint);
    this.baseAPIurl2 = sharedService.splitCurrentURL(apiurlName.eventApiEndPoint);
  }
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }
  public sendMail(customerNumber:string,MailTypeId:number,isLead_NonLead:number,ToMailIds:string,FromMailId:string,FromMailName:string,
    MailType:string,Subject:string,Body:string,MailParam:string):Observable<SendLeadMailRes>{
const param = new HttpParams()
.set('MailTypeId', MailTypeId==null?0:MailTypeId)
.set('isLead_NonLead', isLead_NonLead==null?"":isLead_NonLead)
.set('ToMailIds', ToMailIds==null?"":ToMailIds)
.set('FromMailId', FromMailId==null?"":FromMailId)
.set('FromMailName', FromMailName==null?"":FromMailName)
.set('MailType', MailType==null?"":MailType)
.set('Subject', Subject==null?"":Subject)
.set('Body', Body==null?"":Body)
.set('MailParam', MailParam==null?"":MailParam);
return this.http.get<SendLeadMailRes>(this.baseAPIurl2 + `api/sendmail/${customerNumber}`, {observe: 'response',params:param}).pipe(map(data => {                    
return data.body},catchError(this.handleError)            
));
}
  //customerId = { customerId: sessionStorage.getItem('customerid')}  //Bind Dynamic values for customerid after doing one login
  

  // public getAllsalesOrderDetails():Observable<any>{
  //     return this.http.get(this.baseAPIurl + 'api/leadsalesorders/', {observe: 'response',params:this.customerId}).pipe(map(data => {                      
  //      return data.body},catchError(this.handleError)             
  //   )); 
  // }
  public getAllsalesOrderDetails(isOpenSalesOrder:boolean,customerEmail:string,FromDate,ToDate,userviewtype,isFromSalesOrderPage:boolean):Observable<any>{

    const param = new HttpParams()
    .set('isOpenSalesOrder',isOpenSalesOrder)
     .set('customerEmail',customerEmail)
     .set('FromDate', FromDate==undefined || FromDate==null || FromDate==''?'':FromDate)
     .set('ToDate', ToDate==undefined || ToDate==null || ToDate==''?'':ToDate)
     .set('UserViewType',userviewtype)
     .set('isFromSalesOrderPage',isFromSalesOrderPage);
      return this.http.get(this.baseAPIurl + `api/leadsalesorders`, {observe: 'response',params:param}).pipe(map(data => {                      
       return data.body},catchError(this.handleError)             
    )); 
  }

  public getAllsalesOrderDetailsPagination(isOpenSalesOrder:boolean,customerEmail:string,FromDate,ToDate,userviewtype,isFromSalesOrderPage:boolean, startrownumber, endrownumber, showArchivedData: boolean = true):Observable<any>{
    const param = new HttpParams()
    .set('isOpenSalesOrder',isOpenSalesOrder)
     .set('customerEmail',customerEmail)
     .set('FromDate', FromDate==undefined || FromDate==null || FromDate==''?'':FromDate)
     .set('ToDate', ToDate==undefined || ToDate==null || ToDate==''?'':ToDate)
     .set('UserViewType',userviewtype)
     .set('isFromSalesOrderPage',isFromSalesOrderPage)
     .set('startrownumber',startrownumber)
     .set('endrownumber',endrownumber)
     .set('showArchive', true);
      return this.http.get(this.baseAPIurl + `api/leadsalesorderspagination`, {observe: 'response',params:param}).pipe(map(data => {                      
       return data.body},catchError(this.handleError)             
    )); 
  }


  public getConfigKeyValuePairsDateRange():Observable<any>{
    
    const param = new HttpParams()   
    .set('ApplicationKey','DateRange')    
    .set('ApplicationID','HUB')  
      return this.http.get(this.baseAPIurl + `api/prospect/GetglobalKeyvalue`, {observe: 'response',params:param}).pipe(map(data => {                      
       return data.body},catchError(this.handleError)             
    )); 
  }
  public getPaymentDetails(CustomerNumber:string,emailId:string):Observable<any>{
    //this.baseAPIurl + `api/CardxNetSuitPayment`
    // return this.http.get('https://qa-prospectapi.lrsphub.com/api/CardxNetSuitPayment/BER007', {observe: 'response',params:param}).pipe(map(data => {                      
    const param = new HttpParams()   
      .set('CustomerNumber',CustomerNumber)    
      .set('EmailId',emailId)  
      return this.http.get(this.baseAPIurl + `api/CardxNetSuitPayment/${CustomerNumber}`, {observe: 'response',params:param}).pipe(map(data => {                      
       return data.body},catchError(this.handleError)             
    )); 
  }

  
  submitPaymentACH(customerNumber:string, customerEmail:string, invoiceNumber:string, token:string, paymentAmount:number, payerInfo:string):Observable<any> {
    var params = {
      "customerNumber": customerNumber,
      "customerEmail": customerEmail,
      "transno": invoiceNumber,
      "token": token,
      "paymentAmount": paymentAmount.toString(),
      "payerInfo": payerInfo
    }
    return this.http.post<ACH_Charge_Details[]>(this.baseAPIurl + 'api/cardx', params, {observe: 'response',headers:{ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('admin:admin') }}).pipe(map(data => { return data.body},
      catchError(this.handleError)
    ));
  }

  getEncryptedParameterACH(recordId:string, amountVal:number, custNo:string, payerInfo:string, hubEmail:string):Observable<any> {
    var params = {
      "recordId": recordId,
      "amountVal": amountVal.toString(),
      "custNo": custNo,
      "payerInfo": payerInfo,
      "hubEmail": hubEmail
    }
    return this.http.post<ACH_Form_Details[]>('https://security-api.lrsphub.com/api/encryption', params, {observe: 'response',headers:{ 'Content-Type': 'application/json', 'Authorization': 'Basic ' + btoa('admin:Rk2iK4tZm5I3') }}).pipe(map(data => { return data.body},
      catchError(this.handleError)
    ));
  }

  public getConfigKeyValuePairs():Observable<any>{
    
    const param = new HttpParams()   
      .set('ApplicationKey','HomePage')    
      .set('ApplicationID','HUB')  
      return this.http.get(this.baseAPIurl + `api/prospect/GetglobalKeyvalue`, {observe: 'response',params:param}).pipe(map(data => {                      
       return data.body},catchError(this.handleError)             
    )); 
  }



  // public weeklist: DropDown[];
  //   // Function call for Week
  //    getTimeframes():Observable<any>{
  //     return this.http.get(this.baseAPIurl2,{observe: 'response'}).pipe(map(data => {                      
  //                     httpresponseStatus = data.status
  //                      return data.body},catchError(this.handleError)               
  //           ));      
  //   }  
  // Error handling 
  handleError(error: { error: { message: string; }; status: any; message: any; }) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
 }
}

