import { Component, Input, OnInit } from '@angular/core';
import { iconImages } from '../../../shared/constants/app.constants';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit  {

  @Input() infoTitle: string = '';  //Feature title passed from parent component.
  @Input() smallIcon: boolean = false;  //Determines whether a small icon should be shown.

  infoDialogVisible: boolean;  //Determines whether or not to display the dialog.
  iconTitle: string = '';  //Tooltip for info icon, includes the feature title.

  // Branding for whitelabeling
  public brand: string = 'hub'; // Brand for whitelabeling
  public brandClass: string = 'brand--hub'; // Brand class for whitelabeling
  public componentImages: any = [];
  public imagePath: string = './assets/images/';


  constructor() { 

  }

  ngOnInit(): void {
    // Initialize all variables not directly passed in, including variables derived
    // from passed-in variables.
    this.iconTitle = 'Learn About This Feature: ' + this.infoTitle;
    // Branding for whitelabeling
    if (sessionStorage.getItem('brand') !== '') {
      this.brand = sessionStorage.getItem('brand');
      this.brandClass = 'brand--' + this.brand;
    };
    // Image path for whitelabeling
    this.componentImages = iconImages;
    if (this.brand !== 'hub' && this.brand !== null) {
      this.imagePath = './assets/images/brand-' + this.brand + '/';
    }    
  }

  // Hide the dialog when a close action is triggered.
  closeInfoDialog() {
    this.infoDialogVisible = false;
  }

  // Show the dialog when an open action is triggered.
  openDialog() {
    this.infoDialogVisible = true;
  }


}
