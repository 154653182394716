import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { OneloginAuthService } from '../services/onelogin-auth.service';

@Injectable({
  providedIn: 'root'
})
export class OneloginAuthGuard implements CanActivate {
  constructor(private authService: OneloginAuthService,private router:Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
    if(sessionStorage.getItem('loginuser')=='admin' && sessionStorage.getItem('loginpass')=='admin@123'){
        return true;
    }
      
    if(this.authService.isLoggedIn()) {
        return true
    }
  
      this.authService.startAuthentication()
      return false;
    }
}
  

