<div [ngClass]="brandClass">

    <app-page-loader [showLoader]="showLoader"></app-page-loader>

    <div class="notes__wrapper">
        <p-dialog [(visible)]="showDialog"
            [modal]="true" [baseZIndex]="10000"
            [draggable]="false" [resizable]="false" (onHide)="closeDialog()">
                <ng-template pTemplate="header">
                    <div class="dialog-header">
                        <h2>Notes</h2>
                        <app-information infoTitle="Notes" [smallIcon]=true>
                            <p>Add and Manage Notes for the Prospect. Notes are displayed in order with the most recent at the top and are searchable with in the filters on the main screen.</p>
                        </app-information>        
                    </div>
                </ng-template>

                <section class="notes">
                    <h2 class="notes__title">
                        Notes for {{ prospectName }}
                        <button class="button button--primary" *ngIf="!showAddNote" (click)="addNote()">Add New Note</button>
                    </h2>
                    <div class="notes__add-form" *ngIf="showAddNote">
                        <label class="notes__label">New Note</label>
                        <textarea class="notes__textarea input-control" maxlength="1000" rows="5" cols="20" [(ngModel)]="addNoteContent"></textarea>
                        <div class="notes__buttons">
                            <button class="button button--ghost" (click)="cancelNote()">Cancel</button>
                            <button class="button button--primary" (click)="saveNote()" [disabled]="addNoteContent===''">Save</button>
                        </div>
                        <p-toast></p-toast>
                    </div>
                    <ul class="notes__list">
                        <li class="notes__item" *ngFor="let note of notes; let i=index;">
                            <div class="notes__date">{{ note.dateCreated | date:'MMM dd yyyy h:mm:ss a' }}</div>
                            <div class="notes__delete-icon" (click)="deleteNote(note.responderNoteId ? note.responderNoteId: note.leadNoteId)" title="Delete Note">
                                <img src="./assets/images/TrashBin_Accent.svg" />
                            </div>
                            <div class="notes__content">{{ note.note }}</div>
                        </li>
                    </ul>
                    <div class="notes__buttons">
                        <button class="button button--ghost" (click)="closeDialog()">Close</button>
                    </div>
                </section>
        </p-dialog>
    </div>

    <div class="confirmation-dialog">
        <p-dialog header="Delete Note Confirmation" [(visible)]="showConfirmation" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
            <div class="confirmation-dialog__main">
                <p>Are you sure you want to delete this note?</p>
                <div class="confirmation-dialog__buttons">
                    <button class="button button--ghost" type="button" (click)="cancelDeleteNote()">
                            No
                    </button>
                    <button class="button button--primary" type="button" (click)="confirmDeleteNote()">
                            Yes
                    </button>
                </div>
            </div>
        </p-dialog>
    </div>

</div>