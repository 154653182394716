import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { timer } from 'rxjs';
import { CHART_DATA_OPTIONS, CHART_OPTIONS } from './chartjs-gauge.constants';
const Chart = require('chartjs-gauge');



@Component({
  selector: 'chartjs-gauge',
  templateUrl: './chartjs-gauge.component.html',
  styleUrls: ['./chartjs-gauge.component.scss']
})
export class ChartjsGaugeComponent implements OnInit {
  @Input("value") needleValue:number = 0
  @ViewChild("chartCanvas") chartCanvas!: ElementRef<HTMLCanvasElement>
  context!: CanvasRenderingContext2D | null
  chart: any
  chartConfig: any
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    timer(100).subscribe(() => this.initGauge())
  }

  initGauge() {
    this.chartConfig = {
      type: 'gauge',
      data: CHART_DATA_OPTIONS,
      options: CHART_OPTIONS
    }

    this.context = this.chartCanvas.nativeElement.getContext('2d')
    this.chart = new Chart(this.context, this.chartConfig)
    this.updateValue()
  }

  updateValue() {
    this.chartConfig.data.datasets[0].value = this.needleValue * 10
    this.chart.update()
  }
}
