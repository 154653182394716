
<ngx-spinner
bdColor = "rgb(51,51,51,0.8)"
color="#5e72e4"
size = "medium"
type="ball-clip-rotate"
[fullScreen] = "true"
>
<!-- <img src="../assets/images/loader.gif"/> -->
<br/>
<br/>
<br/>
<p style="color: white; font-size: 25px;" > Loading... </p>
</ngx-spinner>