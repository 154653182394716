import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PageLoaderComponent } from './page-loader.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@NgModule({
    imports: [CommonModule, RouterModule, NgxSpinnerModule, ProgressSpinnerModule],
    declarations: [PageLoaderComponent],
    exports: [PageLoaderComponent]
})
export class PageLoaderModule {}
