import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StandaloneCommunicationService } from '../standalone-communication.service';
import { AttendanceModule } from './attendance.module';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  successMessage: boolean = false;
  errorMessage: boolean = false;
  eventID: any = null;
  eventReady: boolean = false;

  constructor(private route: ActivatedRoute, private communicationService: StandaloneCommunicationService) { }

  ngOnInit(): void {
    // Read the "v" parameter from the URL
    this.route.queryParams.subscribe(params => {
      let v = params['v'];

      // Call the service to decrypt the "v" parameter
      if (v) {
        v = v.replaceAll(" ","%2B");
        this.communicationService.fetchDecryption(v).subscribe(
          decryptedData => {
            if (decryptedData.EventId) {
              this.eventID = decryptedData.EventId;
              this.successMessage = true;
              this.eventReady = true;
            } else {
              this.errorMessage = true;
              console.error('Error retrieving key.');
            }
          },
          decryptionError => {
            this.errorMessage = true;
            console.error('Error retrieving key:', decryptionError);
          }
        );
      } else {
        this.errorMessage = true;
      }
    });
  }
}
