<div *ngIf="!eventID">
    <p *ngIf="errorMessage">Error decrypting data or Event ID not found.</p>
</div>
<div *ngIf="eventReady" class="zoomed-content">
  <div class="standalone">
    <div class="standalone__header-bar">
      <div class="standalone__header">
        <img class="standalone__logo" src="../assets/images/Hub_White.png" />
        <h1 class="standalone__title">Attendance Manager</h1>
      </div>
    </div>
    <div class="standalone__content">
      <app-attendance-manager [eventID]="eventID"></app-attendance-manager>
    </div>
  </div>
</div>