import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StandaloneCommunicationService } from '../standalone-communication.service';

@Component({
  selector: 'app-lead-profile',
  templateUrl: './lead-profile.component.html',
  styleUrls: ['./lead-profile.component.scss']
})
export class LeadProfileComponent implements OnInit {
  authorized: boolean = false;
  leadID: any = null;
  errorMessage: string = '';
  pin: string = '';
  leadData: any;
  pinSubmitted: boolean = false;

  constructor(private cdr: ChangeDetectorRef, private route: ActivatedRoute, private standaloneService: StandaloneCommunicationService) { }

  ngOnInit(): void {
    // Read the "leadID" parameter from the URL
    this.route.queryParams.subscribe(params => {
      this.leadID = params['leadID'];

      if (this.leadID) {
        // this.authorizeLead();
      } else {
        this.errorMessage = 'Lead ID not provided.';
      }
    });
  }

  private authorizeLead(): void {
    console.log("go");
    this.pinSubmitted = true;
    this.standaloneService.accessLeadDetails(this.leadID, this.pin).subscribe(
      (data: any) => {
        let response: any = data;
        if (response && response.verified === true) {
          this.leadData = response;
          this.handleAuthorizationResponse(true);
        } else {
          this.errorMessage = response.errorMessage || 'Unauthorized access to lead.';
          this.cdr.detectChanges();
          this.handleAuthorizationResponse(false);
        }
      },
      (error: any) => {
        this.errorMessage = 'Error occurred while accessing lead details.';
        this.cdr.detectChanges();
        this.handleAuthorizationResponse(false);
      }
    );
  }

  private handleAuthorizationResponse(isAuthorized: boolean): void {
    if (isAuthorized) {
      this.errorMessage = '';
      this.authorized = true;
    } else {
      this.pin = '';
    }
  }
}
