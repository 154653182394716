<section class="event-header" [ngClass]="{ 'event-header--large' : largeSize, 'event-header--full' : showDateCard }">
    <div class="event-header__date date-card" *ngIf="showDateCard">
        <div class="date-card__month" [ngClass]="eventClass">{{event.date | date:'MMM' | uppercase }}</div>
        <div class="date-card__date" [ngClass]="eventClass">{{event.date | date:'d'}}</div>
    </div>
    <div class="event-header__main venue-card">
        <div class="venue-card__top" [ngClass]="eventClass">
            <img class="venue-card__icon" [src]="eventIcon">
            <div>
                <div class="venue-card__event-type">{{event.eventType}}</div>
                <div class="venue-card__date">
                    <span *ngIf="event.time">{{ event.date | date:'fullDate' }} - {{ event.time.charAt(0) === "0" ? event.time.substring(1) : event.time }}</span>
                    <span *ngIf="!event.time">{{ event.eventDate | date:'fullDate' }} - {{ event.eventDate | date:'shortTime' }}</span>
                </div>
            </div>
        </div>
        <div class="venue-card__middle" [ngClass]="eventClass" *ngIf="showCampaignData">
            <div class="venue-card__title">{{event.campaignName}}</div>  
            <div class="venue-card__advisor">{{event.advisor}}</div>  
        </div>
        <div class="venue-card__bottom" [ngClass]="eventClass">
            <div class="venue-card__main">
                <div class="venue-card__venue">{{event.venueName}}</div>
                <div class="venue-card__location">
                    <span class="venue-card__address">{{event.addressLine1}}</span>&nbsp;
                    <span class="venue-card__city-state">{{event.city}}, {{event.state}}</span>
                </div>
                <span class="venue-card__webinar-link" *ngIf="showLinks && event.webinarUrl != '' &&  event.webinarUrl != null">
                    <a class="venue-card__link" title="Webinar Room Link" target="_blank" href={{event.webinarUrl}}>
                        Webinar Room Link
                        <img src="./assets/images/ExternalLink_Primary.svg">
                    </a>
                </span>        
            </div>
            <div class="venue-card__menu" [ngClass]="event.typeClass" *ngIf="showMeals">
                <div class="venue-card__menu-item" *ngFor="let menuitem of event.menu">
                    <div class="venue-card__menu-label">{{menuitem.menuItem}}:</div>
                    <div class="venue-card__menu-count">{{menuitem.totalResponderCount}}</div>
                </div>
                <div class="venue-card__menu-item" *ngIf="event.menu.length === 0">
                    <div class="venue-card__menu-label">No menu selections for this event</div>
                </div>
            </div>
        </div>
    </div>    
</section>
